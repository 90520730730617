/* eslint-disable no-unneeded-ternary */
import { isString, isNil } from 'lodash'
import e_SortOrder from '@appfarm/common/enums/e_SortOrder'

const getSortValue = (object, { additionalSortDataDict, sortDescriptor, enumValueToIndex } = {}) => {
	let edgeObject = object
	if (sortDescriptor?.sortField?.edgeDataBinding && additionalSortDataDict)
		edgeObject = additionalSortDataDict[object._id]

	const value = edgeObject[sortDescriptor.sortNodeName]

	if (enumValueToIndex && !isNil(value) && !isNil(enumValueToIndex[value])) {
		return enumValueToIndex[value]
	} else {
		return value
	}
}

// TODO: Probably need to support multi cardinality (refs and enums)
const dataSourceObjectSorter = (
	objectArray,
	sortDescriptorArray,
	{ additionalSortDataDict, appController } = {}
) => {
	if (!objectArray) return { result: objectArray, didSort: false }
	if (!sortDescriptorArray) return { result: objectArray, didSort: false }
	if (!sortDescriptorArray.length) return { result: objectArray, didSort: false }

	let didSort = false
	const result = sortDescriptorArray.reduceRight(
		(sortedObjects, sortDescriptor) => {
			const sortValueNegative = sortDescriptor.sortOrder === e_SortOrder.ASCENDING ? -1 : 1
			const sortValuePositive = sortDescriptor.sortOrder === e_SortOrder.ASCENDING ? 1 : -1

			const positiveSortFlag = sortDescriptor.sortOrder === e_SortOrder.ASCENDING ? false : true
			const negativeSortFlag = sortDescriptor.sortOrder === e_SortOrder.ASCENDING ? true : false

			let enumValueToIndex
			if (appController && sortDescriptor.sortField?.enumeratedTypeId) {
				const enumType = appController.getEnumeratedType(sortDescriptor.sortField.enumeratedTypeId)
				enumValueToIndex = enumType.values.reduce((dict, enumValue, index) => {
					dict[enumValue.value] = index
					return dict
				}, {})
			}

			sortedObjects.sort((a, b) => {
				let valueA = getSortValue(a, { additionalSortDataDict, sortDescriptor, enumValueToIndex })
				let valueB = getSortValue(b, { additionalSortDataDict, sortDescriptor, enumValueToIndex })

				// nil -> null or undefined
				if (isNil(valueA)) {
					if (positiveSortFlag) didSort = true
					return sortValuePositive
				}
				if (isNil(valueB)) {
					if (negativeSortFlag) didSort = true
					return sortValueNegative
				}

				if (isString(valueA)) valueA = valueA.toUpperCase()
				if (isString(valueB)) valueB = valueB.toUpperCase()

				if (valueA < valueB) {
					if (negativeSortFlag) didSort = true
					return sortValueNegative
				}

				if (valueA > valueB) {
					if (positiveSortFlag) didSort = true
					return sortValuePositive
				}

				return 0
			})

			return sortedObjects
		},
		[...objectArray]
	)

	return { result, didSort }
}

export default dataSourceObjectSorter
