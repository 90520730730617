import React, { useState, useEffect, Fragment, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isPlainObject, isUndefined, isNil } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { t } from '@lingui/macro'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Checkbox from '@material-ui/core/Checkbox'

import e_SelectionEffectType from '@appfarm/common/enums/e_SelectionEffectType'
import { e_MultiSelectType, e_TagDisplay } from '@appfarm/common/enums/e_PropertyTypes'

import { makeEvaluateFilter } from '#selectors/filterSelectors'
import { generateFilterFromGroupNode } from '#utils/filterGenerator'

import UiVirtualizedListBox from '../UiVirtualizedListBox'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'block',
	},
	inputRoot: {
		width: '100%',
	},
	placeholderActive: {
		opacity: theme.palette.type === 'light' ? 0.42 : 0.5,
	},
	checkbox: {
		padding: 4,
	},
	listItemText: {
		fontSize: 12,
	},
	item: {
		display: 'flex',
	},
}))

const getPropertyValueFromDataBinding = (value, propertyIdent) => {
	if (!value) return undefined
	if (value.referenceDataBinding) return value.referenceDataBinding[propertyIdent]
	return value[propertyIdent]
}

const UiMultiSelect = (props) => {
	const classes = useStyles()

	const [inputValue, setInputValue] = useState('')
	const [helperText, setHelperText] = useState('')
	const [label, setLabel] = useState('')
	const [placeholder, setPlaceholder] = useState('')
	const [excessTagsDisplay, setExcessTagsDislay] = useState('...')

	const filterFunction = useMemo(makeEvaluateFilter, [])

	useEffect(() => {
		if (props.component.helperText) {
			const helperText = props.appController.getDataFromDataValue(
				props.component.helperText,
				props.contextData,
				{ getDisplayValue: true }
			)
			setHelperText(helperText)
		}
	}, [props.component.helperText])

	useEffect(() => {
		if (props.component.label) {
			const label = props.appController.getDataFromDataValue(props.component.label, props.contextData, {
				getDisplayValue: true,
			})
			setLabel(label)
		}
	}, [props.component.label])

	useEffect(() => {
		if (props.component.placeholder) {
			const placeholder = props.appController.getDataFromDataValue(
				props.component.placeholder,
				props.contextData,
				{
					getDisplayValue: true,
				}
			)
			setPlaceholder(placeholder)
		}
	}, [props.component.placeholder])

	useEffect(() => {
		if (props.component.excessTagsDisplay) {
			const excessTagsDisplay = props.appController.getDataFromDataValue(
				props.component.excessTagsDisplay,
				props.contextData,
				{ getDisplayValue: true }
			)
			setExcessTagsDislay(excessTagsDisplay)
		}
	}, [props.component.excessTagsDisplay])

	let options = []
	const enumeratedTypeId = getPropertyValueFromDataBinding(props.component.value, 'enumeratedTypeId')
	if (enumeratedTypeId) {
		if (props.component.conditionalEnumOptions && props.component.conditionalEnumOptions.length) {
			const conditionalItem = props.component.conditionalEnumOptions.find((item) =>
				props.appController.getDataFromDataValue(item.condition, props.contextData)
			)

			if (conditionalItem) {
				options = props.appController.getEnumeratedTypeOptions({
					enumeratedTypeId: enumeratedTypeId,
					limitEnumeratedTypeValues: true,
					selectableEnumTypeValues: conditionalItem.selectableEnumTypeValues,
				})
			} else {
				options = props.appController.getEnumeratedTypeOptions({
					enumeratedTypeId: enumeratedTypeId,
				})
			}
		} else {
			options = props.appController.getEnumeratedTypeOptions({
				enumeratedTypeId: enumeratedTypeId,
			})
		}
	} else if (props.component.optionsDataSource && filterFunction) {
		let filter
		let hasFilter = false
		if (props.component.filterDescriptor) {
			hasFilter = true
			filter = generateFilterFromGroupNode({
				filterDescriptorNode: props.component.filterDescriptor,
				contextData: props.contextData,
				appController: props.appController,
			})
		}

		if (hasFilter && isUndefined(filter)) {
			options = []
		} else {
			options = props.appController.getObjectSelectOptions({
				dataSourceId: props.component.optionsDataSource,
				displayValueDataBinding: props.component.displayValue,
				filter: filter,
				filterFunction: filterFunction,
				contextData: props.contextData,
				conditionalFilter: props.component.conditionalFilter,
			})
		}
	}

	const optionDict = options.reduce((acc, option) => {
		if (!acc[option.id]) {
			acc[option.id] = option
		}
		return acc
	}, {})

	let value = []
	if (props.component.multiSelectType === e_MultiSelectType.MULTI_PROPERTY && enumeratedTypeId) {
		const nodeName = getPropertyValueFromDataBinding(props.component.value, 'nodeName')
		const valueIds = isPlainObject(props.ownData) && Object.keys(optionDict).length && props.ownData[nodeName]
		value = valueIds
			? valueIds.map((id) => {
				const valueOption = optionDict[id]
				if (valueOption) return valueOption

				const enumeratedTypeNameDict = props.appController.getEnumeratedTypeNameDict(enumeratedTypeId)
				const enumName = enumeratedTypeNameDict[id]

				return {
					id,
					value: id,
					name: enumName || id,
					hide: true,
				}
			})
			: []
	} else if (props.component.multiSelectType === e_MultiSelectType.MULTI_PROPERTY && props.component.value) {
		const nodeName = getPropertyValueFromDataBinding(props.component.value, 'nodeName')
		const valueIds = isPlainObject(props.ownData) && props.ownData[nodeName]
		value = valueIds
			? valueIds.map((id) => {
				const valueOption = optionDict[id]
				if (valueOption) return valueOption

				// TODO: improve display of value not amoung options
				return {
					id,
					value: id,
					name: id,
					hide: true,
				}
			})
			: []
	} else if (props.component.optionsDataSource) {
		let ownData
		const ownDataSource = props.appController.getDataSource(props.component.optionsDataSource)
		if (props.component.filterDescriptor) {
			ownData = ownDataSource.getObjectsBySelectionType({
				selectionType: e_SelectionEffectType.FILTERED,
				staticFilter: props.component.staticFilter,
				filterDescriptor: props.component.filterDescriptor,
				contextData: props.contextData,
			})
		} else {
			ownData = ownDataSource.getObjectsBySelectionType({
				selectionType: e_SelectionEffectType.ALL,
				contextData: props.contextData,
			})
		}

		if (ownData && ownData.length && Object.keys(optionDict).length) {
			value = ownData.filter((item) => !!item.__SELECTED__).map((item) => optionDict[item._id])
		}
	}

	const onValueChange = useCallback(
		(event, value) => {
			const newValue = !isNil(value) && value !== '' ? value : undefined
			if (props.component.multiSelectType === e_MultiSelectType.MULTI_PROPERTY) {
				const nodeName = getPropertyValueFromDataBinding(props.component.value, 'nodeName')
				const previousValue = props.ownData ? props.ownData[nodeName] : undefined

				const isEnum = !!getPropertyValueFromDataBinding(props.component.value, 'enumeratedTypeId')

				const previousValues = {
					previousValueEnum: isEnum ? previousValue : null,
					previousValueReference: isEnum ? null : previousValue,
				}

				const onValueChangeEvent = props.component.onValueChange
					? () =>
						props.eventHandler(
							props.component.onValueChange,
							null,
							{ eventType: 'onValueChange', eventHandlerValues: previousValues },
							event
						)
					: undefined

				props.appController.modifySingleValue(
					props.component.value,
					props.ownData,
					newValue,
					props.contextData,
					onValueChangeEvent
				)
			} else {
				if (props.component.optionsDataSource) {
					const optionsDataSource = props.appController.getDataSource(props.component.optionsDataSource)
					const previousSelectedData = optionsDataSource.getSelectedObjects()

					const isEnum = !!optionsDataSource?.enumeratedTypeId

					const previousValues = {
						previousValueEnum:
							isEnum && previousSelectedData.length > 0
								? previousSelectedData.map((item) => item.enum_value)
								: null,
						previousValueReference:
							!isEnum && previousSelectedData.length > 0
								? previousSelectedData.map((item) => item._id)
								: null,
					}

					props.appController.setSelection(props.component.optionsDataSource, newValue)

					if (props.component.onValueChange)
						props.eventHandler(
							props.component.onValueChange,
							null,
							{ eventType: 'onValueChange', eventHandlerValues: previousValues },
							event
						)
				}
			}
		},
		[
			props.contextData,
			props.component.value,
			props.ownData,
			props.component.onValueChange,
			props.component.optionsDataSource,
			props.eventHandler,
			props.appController,
		]
	)

	const onValueChangeAutocomplete = (event, options) => {
		onValueChange(
			event,
			options.map((option) => option.id)
		)
	}

	const onValueChangeSelect = (event) => {
		onValueChange(event, event.target.value)
	}

	const handleOpen = useCallback(
		(event) => {
			if (props.component.onOpen)
				props.eventHandler(props.component.onOpen, null, { eventType: 'onOpen' }, event)
		},
		[props.component.onOpen, props.eventHandler]
	)

	const handleClose = useCallback(
		(event) => {
			if (props.component.onClose)
				props.eventHandler(props.component.onClose, null, { eventType: 'onClose' }, event)
		},
		[props.component.onClose, props.eventHandler]
	)

	const { component, componentId, disabled, readOnly, error, styleProp, conditionalClassNames } = props
	const {
		autoFocus,
		marginType,
		variant,
		disableUnderline,
		tabIndex,
		autocomplete,
		limitTags,
		persistentInput,
	} = component

	// Autocomplete does not respect readOnly, work-around is thus to render normal multiselect if readOnly.
	if (autocomplete && !readOnly) {
		let renderTags
		if (component.tagDisplay === e_TagDisplay.CHIP) {
			renderTags = (value, getTagProps) => {
				const items = !isNil(limitTags) ? value.slice(0, limitTags) : value
				const excessItems = !isNil(limitTags) && value.length - limitTags > 0 ? value.length - limitTags : 0
				if (excessItems) {
					const name = excessTagsDisplay.replace(/{{excessTagsCount}}/g, excessItems)
					items.push({
						id: 'excess-items',
						value: 'excess-items',
						name: name,
					})
				}
				return items.map((item, index) => {
					if (!item) return <span />

					if (item.id === 'excess-items') {
						return <div key={item.id}>{ item.name }</div>
					}
					return (
						<Chip
							key={item.id}
							size="small"
							variant="outlined"
							label={item.name || t`[No value]`}
							{...getTagProps({ index })}
						/>
					)
				})
			}
		} else {
			renderTags = (value) => {
				const items = !isNil(limitTags) ? value.slice(0, limitTags) : value
				const excessItems = !isNil(limitTags) && value.length - limitTags > 0 ? value.length - limitTags : 0
				if (excessItems) {
					const name = excessTagsDisplay.replace(/{{excessTagsCount}}/g, excessItems)
					items.push({
						id: 'excess-items',
						value: 'excess-items',
						name: name,
					})
				}
				return items.map((item, index) => (
					<Fragment key={item.id}>
						{ item.name }
						{ index + 1 < items.length && ', ' }
					</Fragment>
				))
			}
		}

		let persistentInputProps = {}
		if (persistentInput) {
			const onInputChange = (event, value, reason) => {
				if (event && event.type === 'blur') {
					setInputValue('')
				} else if (reason !== 'reset') {
					setInputValue(value)
				}
			}
			persistentInputProps = {
				inputValue: inputValue,
				onInputChange: onInputChange,
			}
		}

		return (
			<Autocomplete
				id={componentId}
				options={options}
				multiple
				includeInputInList
				disableCloseOnSelect
				disabled={disabled}
				value={value || ''}
				onChange={onValueChangeAutocomplete}
				onOpen={handleOpen}
				onClose={handleClose}
				className={classNames(classes.root, 'c' + component.id, conditionalClassNames)}
				style={styleProp}
				ListboxComponent={UiVirtualizedListBox}
				{...persistentInputProps}
				renderTags={renderTags}
				renderOption={(item, { selected }) => {
					return (
						<div className={classNames(classes.item)}>
							{ !item.hideCheckbox && (
								<ListItemIcon>
									<Checkbox
										className={classes.checkbox}
										disableRipple
										checked={selected}
										color="primary"
										tabIndex={-1}
									/>
								</ListItemIcon>
							) }
							<ListItemText primary={item.name} classes={{ primary: classes.listItemText }} />
						</div>
					)
				}}
				getOptionLabel={(option) => (option.name ? option.name + '' : t`No value`)}
				renderInput={(params) => {
					const InputProps = {
						...params.InputProps,
						className: classNames(params.InputProps.className, classes.inputRoot),
						// readOnly, // only sets the inputfield to read only
					}
					const InputLabelProps = {
						...params.InputLabelProps,
					}

					if (placeholder && !value?.length) {
						InputProps.placeholder = placeholder
						InputLabelProps.shrink = true
					}

					if (disableUnderline) {
						InputProps.disableUnderline = true
					}

					return (
						<TextField
							{...params}
							fullWidth
							margin={marginType}
							variant={variant}
							label={label}
							helperText={helperText}
							error={error}
							autoFocus={autoFocus}
							inputProps={{
								...params.inputProps,
								tabIndex,
							}}
							InputProps={InputProps}
							InputLabelProps={InputLabelProps}
						/>
					)
				}}
			/>
		)
	}
	const renderValue = () => (value || []).map((item) => item.name).join(', ') || placeholder

	const InputProps = {
		className: classes.inputRoot,
		readOnly,
		inputProps: {
			tabIndex,
		},
	}

	const SelectProps = {
		multiple: true,
		displayEmpty: !!placeholder,
		classes:
			!!placeholder && (!value || value.length === 0) ? { root: classes.placeholderActive } : undefined,
		renderValue: renderValue,
		onOpen: handleOpen,
		onClose: handleClose,
	}

	const InputLabelProps = placeholder ? { shrink: !!placeholder } : undefined

	if (disableUnderline) InputProps.disableUnderline = true

	return (
		<TextField
			id={componentId}
			value={value.map((item) => item.id) || ''}
			onChange={onValueChangeSelect}
			className={classNames(classes.root, 'c' + component.id, conditionalClassNames)}
			margin={marginType}
			style={styleProp}
			variant={variant}
			label={label}
			helperText={helperText}
			error={error}
			disabled={disabled}
			autoFocus={autoFocus}
			InputProps={InputProps}
			InputLabelProps={InputLabelProps}
			SelectProps={SelectProps}
			select
		>
			{ options.map((item) => {
				const isSelected = !!(value && value.length && value.map((item) => item.id).indexOf(item.value) > -1)
				return (
					<MenuItem key={item.id} value={item.value} dense disabled={!!item.disabled} selected={isSelected}>
						{ !item.hideCheckbox && (
							<ListItemIcon>
								<Checkbox
									className={classes.checkbox}
									disableRipple
									checked={isSelected}
									color="primary"
									tabIndex={-1}
								/>
							</ListItemIcon>
						) }
						<ListItemText primary={item.name} classes={{ primary: classes.listItemText }} />
					</MenuItem>
				)
			}) }
		</TextField>
	)
}

UiMultiSelect.propTypes = {
	appController: PropTypes.shape({
		setSelection: PropTypes.func.isRequired,
		getDataSource: PropTypes.func.isRequired,
		modifySingleValue: PropTypes.func.isRequired,
		getEnumeratedTypeOptions: PropTypes.func.isRequired,
		getEnumeratedTypeNameDict: PropTypes.func.isRequired,
		getObjectSelectOptions: PropTypes.func.isRequired,
		getObjectsBySelectionType: PropTypes.func.isRequired,
		getDataFromDataValue: PropTypes.func.isRequired,
		getDisplayValueFromDataBinding: PropTypes.func.isRequired,
	}).isRequired,
	component: PropTypes.object.isRequired,
	componentId: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	readOnly: PropTypes.bool,
	error: PropTypes.bool,
	eventHandler: PropTypes.func.isRequired,
	styleProp: PropTypes.object,
	ownData: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
	contextData: PropTypes.object,
	conditionalClassNames: PropTypes.string,
}

UiMultiSelect.defaultProps = {
	contextData: {},
}

export default UiMultiSelect
