/* eslint-disable import/prefer-default-export */
import { isString, isNil, isPlainObject, isUndefined, isArray } from 'lodash'
import { t } from '@lingui/macro'
import * as Sentry from '@sentry/browser'

import e_DataValueType from '@appfarm/common/enums/e_DataValueType'
import e_InsertObjectsOperation from '@appfarm/common/enums/e_InsertObjectsOperation'
import e_Cardinality from '@appfarm/common/enums/e_Cardinality'
import e_BuiltInObjectPropertyIds from '@appfarm/common/enums/e_BuiltInObjectPropertyIds'
import e_ValueProcessorOperation from '@appfarm/common/enums/e_ValueProcessorOperation'
import e_DataSourceType from '@appfarm/common/enums/e_DataSourceType'
import e_DateValueComponents from '@appfarm/common/enums/e_DateValueComponents'
import e_SelectionEffectType from '@appfarm/common/enums/e_SelectionEffectType'
import e_SelectionOperationType from '@appfarm/common/enums/e_SelectionOperationType'
import e_FilterTargetSelectionMode from '@appfarm/common/enums/e_FilterTargetSelectionMode'
import e_BuiltInDataSourceAttributeIds from '@appfarm/common/enums/e_BuiltInDataSourceAttributeIds'
import e_DataSourceChangeType from '@appfarm/common/enums/e_DataSourceChangeType'

import ValueProcessor from '@appfarm/common/utils/ValueProcessor'
import dateFormatter from '@appfarm/common/utils/dateFormatter'
import mechanicalUserDefinition from '@appfarm/common/builtins/mechanicalUserDefinition'
import builtInCurrentUserGroupsDefinition from '@appfarm/common/builtins/builtInCurrentUserGroupsDefinition'
import { getAllBuiltInPropertiesDict } from '@appfarm/common/builtins/builtInObjectProperties'
import makeGetConstantFunctionValueFromDataValue from '@appfarm/common/utils/makeGetConstantFunctionValueFromDataValue'

import {
	MODIFY_OBJECT,
	REPLACE_DATA_IN_DATASOURCE,
	INSERT_OBJECT,
	INSERT_MULTIPLE_OBJECTS,
	DELETE_MULTIPLE_OBJECTS,
} from '#actions/actionTypes'
import logger, { liveUpdateLogger } from '#logger/logger'
import { isAppReady, getNormalViews } from '#selectors/metadataSelectors'
import { getSideEffects } from '#modules/afClientApi'
import { evaluateCondition } from '#utils/conditionEvaluator'
import evaluateFunctionValue from '#utils/functionEvaluator'
import { getActionParamFromContextData, getIteratorParamFromContextData } from '#utils/contextDataUtils'
import { generateFilterFromGroupNode } from '#utils/filterGenerator'
import getReturnValueFromDataObject from '#utils/getReturnValueFromDataObject'
import { getStore } from '../../store/reduxStoreUtils'

import AppTimer from '../AppTimer'
import metadataController from '../MetadataController/metadataControllerInstance'
import ClientDataSource from '../DataSource/ClientDataSource'
import AppVariableDataSource from '../DataSource/AppVariableDataSource'
import CalendarDataSource from '../DataSource/CalendarDataSource'
import EnumDataSource from '../DataSource/EnumDataSource'
import StaticDataSource from '../DataSource/StaticDataSource'
import UrlPathDataSource from '../DataSource/UrlPathDataSource'
import UrlParamsDataSource from '../DataSource/UrlParamsDataSource'
import localeController from '../localeControllerInstance'

import dayjs from '../dayjs'

// TODO: Flytt til egnet sted
const operations = {
	GET_SELECTED_OBJECT_IDS: 'GET_SELECTED_OBJECT_IDS',
	GET_SINGLE_OBJECT: 'GET_SINGLE_OBJECT',
	GET_SINGLE_VALUE: 'GET_SINGLE_VALUE',
	GET_OBJECT_BY_ID: 'GET_OBJECT_BY_ID',
	GET_FILTERED_OBJECT_IDS: 'GET_FILTERED_OBJECT_IDS',
	GET_FULL_DATASOURCE: 'GET_FULL_DATASOURCE',
	GET_OBJECT_BY_SELECTION_TYPE: 'GET_OBJECT_BY_SELECTION_TYPE',
	GET_RUNTIME_OBJECT_VALUES_DICT: 'GET_RUNTIME_OBJECT_VALUES_DICT',
}

const getDataSourceConstructor = (dataSource) => {
	let DataSourceConstructor = ClientDataSource

	if (dataSource.id === '__BUILTIN_RUNTIME_STATE__DS__') {
		DataSourceConstructor = AppVariableDataSource
	} else if (dataSource.id === '__BUILTIN_URL_PATH__DS__') {
		DataSourceConstructor = UrlPathDataSource
	} else if (dataSource.id === '__BUILTIN_URL_PARAMS__DS__') {
		DataSourceConstructor = UrlParamsDataSource
	} else if (dataSource.isCalendar) {
		DataSourceConstructor = CalendarDataSource
	} else if (dataSource.dataSourceType === e_DataSourceType.ENUMERATED_TYPE) {
		DataSourceConstructor = EnumDataSource
	}

	return DataSourceConstructor
}

// DataController??
export class AppController {
	constructor() {
		this.logger = logger.createChildLogger({ prefix: 'AppController' })

		this.dataSourcesList = []
		this.dataSourcesDict = {}
		this.appVariableDataSource = null
		this.timers = []
		this.topics = {}

		this.enumeratedTypeDict = {}

		this.__metadataController = metadataController
		this.originalMetadata = null

		// Runtime State
		this.activeAppId = null
		this.enableRenderFlag = true
		this.runningActionsDict = {}
		this.activeActionRunners = {}

		// Subscriptions
		this.maxLevel = 0
		this.componentSubscriptions = []
		this.componentLevelSubscriptions = {}
		this.updateReference = 0
		this.devtoolSubscription = null

		// Language
		this.activeLanguageId = null

		// Metadata load flag
		this.pendingDataModel = true
		this.pendingFunctions = true
		this.pendingStylesheet = true

		this.valueProcessor = new ValueProcessor(dayjs)
		this.__getConstantFunctionValueFromDataValue = makeGetConstantFunctionValueFromDataValue(dayjs)

		// Function Bindings
		this.getBooleanLabelOptions = this.getBooleanLabelOptions.bind(this)
		this.getDataSource = this.getDataSource.bind(this)
		this.getDataSourceFromDataBindingProperty = this.getDataSourceFromDataBindingProperty.bind(this)
		this.getDataSourceIdFromDataBindingProperty = this.getDataSourceIdFromDataBindingProperty.bind(this)
		this.getAppVariablesDataSource = this.getAppVariablesDataSource.bind(this)
		this.getDisplayValueFromDataBinding = this.getDisplayValueFromDataBinding.bind(this)
		this.getDataFromDataBinding = this.getDataFromDataBinding.bind(this)
		this.getObjectsFromDataBindingProperty = this.getObjectsFromDataBindingProperty.bind(this)
		this.enrichObjectIdsFromDataBindingProperty = this.enrichObjectIdsFromDataBindingProperty.bind(this)
		this.getPrimitiveValueFromDataBinding = this.getPrimitiveValueFromDataBinding.bind(this)
		this.getDataFromDataValue = this.getDataFromDataValue.bind(this)
		this.getValueFromValueComponentId = this.getValueFromValueComponentId.bind(this)
		this.getEnumeratedType = this.getEnumeratedType.bind(this)
		this.getEnumeratedTypeValue = this.getEnumeratedTypeValue.bind(this)
		this.getEnumeratedTypeOptions = this.getEnumeratedTypeOptions.bind(this)
		this.getCurrentUserId = this.getCurrentUserId.bind(this)

		// Metadata setters
		this.setActiveApp = this.setActiveApp.bind(this)
		this.setOrUpdateApp = this.setOrUpdateApp.bind(this)
		this.setOrUpdateDataModel = this.setOrUpdateDataModel.bind(this)

		this.debugGetDataSourcesInUse = this.debugGetDataSourcesInUse.bind(this)
	}

	// TODO: Satser på å få vekk alt av getState herfra
	getState() {
		return getStore().getState()
	}

	/**************************************************************************
	 *
	 * React Component API
	 *
	 *************************************************************************/

	subscribeComponent(handler, componentLevel) {
		if (this.maxLevel < componentLevel) this.maxLevel = componentLevel

		if (!this.componentLevelSubscriptions[componentLevel])
			this.componentLevelSubscriptions[componentLevel] = []

		this.componentLevelSubscriptions[componentLevel].push(handler)
	}

	unsubscribeComponent(handler, componentLevel) {
		this.componentLevelSubscriptions[componentLevel] = this.componentLevelSubscriptions[
			componentLevel
		].filter((item) => item !== handler)
	}

	/**************************************************************************
	 *
	 * Render and Data Control
	 *
	 *************************************************************************/

	stopRender() {
		this.enableRenderFlag = false
	}
	resumeRender() {
		this.enableRenderFlag = true
		this._writeDiryDataSourcesToRedux()
	}

	isRenderEnabled() {
		const runningActions = this.runningActionsDict
		const blockingActionsRunning = Object.keys(runningActions).some(
			(actionId) => runningActions[actionId].pauseRender
		)
		if (blockingActionsRunning) return false

		if (this.pendingDataModel) return false
		if (this.pendingFunctions) return false
		if (this.pendingStylesheet) return false

		return this.enableRenderFlag
	}

	queueDataUpdate() {
		clearTimeout(this.dateUpdateQueueTimer)
		this.dateUpdateQueueTimer = setTimeout(() => {
			this._writeDiryDataSourcesToRedux()
		}, 10)
	}

	_writeDiryDataSourcesToRedux() {
		if (!this.isRenderEnabled()) return

		// Update calendars
		this.dataSourcesList.forEach((item) => {
			if (item.isCalendar) {
				item.evaluateRange()
			}
		})

		const changedDataSourceIds = []
		let dataDidChange = false
		const changeDescriptors = this.dataSourcesList
			.filter((dataSource) => dataSource.changeDescriptor.pendingChange)
			.reduce((dict, dataSource) => {
				dataDidChange = true
				changedDataSourceIds.push(dataSource.id)
				dict[dataSource.id] = dataSource.readAndResetChangeDescriptor()
				return dict
			}, {})

		this.__debugUiUpdateLogEnabled && this.logger.debug('Change Descriptors:', { payload: changeDescriptors })

		if (dataDidChange) {
			// Notify timers - Maybe put this in a component subscription
			this.timers.forEach((timer) => timer.evalEnable())

			// Used to mark every data update uniquely
			this.updateReference++
			this.__debugUiUpdateLogEnabled && this.logger.debug('Store update reference: ' + this.updateReference)

			// Update from parrents and down
			for (let i = 0; i <= this.maxLevel; i++) {
				const subscriptions = this.componentLevelSubscriptions[i]
				if (subscriptions) {
					let updateText
					if (this.__debugUiUpdateLogEnabled) {
						updateText = `${i}: Updated ${subscriptions.length} components`
						this.logger.time(updateText)
					}
					subscriptions.forEach((sub) => sub(changedDataSourceIds, this.updateReference, changeDescriptors))

					this.__debugUiUpdateLogEnabled && this.logger.timeEnd(updateText)
				}
			}

			this.devtoolSubscription && this.devtoolSubscription(changedDataSourceIds, this.updateReference)
		}
	}

	_discardUpdatesForUiButEvalOther() {
		const changedDataSourceIds = []
		let dataDidChange = false
		const changeDescriptors = this.dataSourcesList
			.filter((dataSource) => dataSource.changeDescriptor.pendingChange)
			.reduce((dict, dataSource) => {
				dataDidChange = true
				changedDataSourceIds.push(dataSource.id)
				dict[dataSource.id] = dataSource.readAndResetChangeDescriptor()
				return dict
			}, {})

		// Eval timers anyway
		if (dataDidChange) {
			this.updateReference++
			this.timers.forEach((timer) => timer.evalEnable())
			this.devtoolSubscription &&
				this.devtoolSubscription(changedDataSourceIds, this.updateReference, changeDescriptors)
		}
	}

	/**************************************************************************
	 *
	 * Getters
	 *
	 *************************************************************************/

	getDataSource(dataSourceId) {
		return this.dataSourcesDict[dataSourceId]
	}

	getDataSourceIdFromDataBindingProperty(dataBinding) {
		if (!dataBinding) return
		if (!dataBinding.dataSourceId) return
		if (!dataBinding.propertyId) return dataBinding.dataSourceId

		// Value used only for contextData- no actual datasource
		if (dataBinding.enumeratedTypeId) return `${dataBinding.dataSourceId}.${dataBinding.enumeratedTypeId}`

		const dataSource = this.getDataSource(dataBinding.dataSourceId)
		if (!dataSource) return
		const property = dataSource.propertiesMetaDict[dataBinding.propertyId]
		if (!property || !property.referenceId) return

		let referenceDataSourceId
		const referenceDataSourceDesc =
			dataSource.referenceDataSources &&
			dataSource.referenceDataSources.find((item) => item.referenceId === property.referenceId)
		if (referenceDataSourceDesc?.dataSourceId) referenceDataSourceId = referenceDataSourceDesc.dataSourceId
		else referenceDataSourceId = `${dataBinding.dataSourceId}.${property.referenceId}`

		return referenceDataSourceId
	}

	getDataSourceFromDataBindingProperty(dataBinding) {
		if (!dataBinding.dataSourceId) return
		const dataSource = this.getDataSource(dataBinding.dataSourceId)
		if (!dataSource) return
		if (!dataBinding.propertyId) return dataSource
		const property = dataSource.propertiesMetaDict[dataBinding.propertyId]
		if (!property || !property.referenceId) return

		let referenceDataSourceId
		const referenceDataSourceDesc =
			dataSource.referenceDataSources &&
			dataSource.referenceDataSources.find((item) => item.referenceId === property.referenceId)
		if (referenceDataSourceDesc?.dataSourceId) referenceDataSourceId = referenceDataSourceDesc.dataSourceId
		else referenceDataSourceId = `${dataBinding.dataSourceId}.${property.referenceId}`

		return this.getDataSource(referenceDataSourceId)
	}

	getObjectsFromDataBindingProperty(dataBinding, { contextData } = {}) {
		const object = this.getDataFromDataBinding({
			contextData,
			dataBinding: dataBinding,
		})
		if (!object) return []

		let objectIds = object[dataBinding.nodeName]
		if (!objectIds) return []

		if (!isArray(objectIds)) objectIds = [objectIds]

		return this.enrichObjectIdsFromDataBindingProperty(dataBinding, { contextData, objectIds })
	}

	enrichObjectIdsFromDataBindingProperty(dataBinding, { contextData, objectIds } = {}) {
		if (isNil(objectIds) || !objectIds.length) return []

		if (dataBinding.enumeratedTypeId) {
			const enumeratedType = this.getEnumeratedType(dataBinding.enumeratedTypeId)

			return objectIds.map((value) => {
				const enumTypeValue = enumeratedType.valueDict[value] || {}
				return {
					_id: value,
					value,
					...enumTypeValue,
				}
			})
		}

		const dataSource = this.getDataSourceFromDataBindingProperty(dataBinding)

		let referenceDataDict = {}
		if (dataSource) {
			const selectionType = e_SelectionEffectType.FILTERED
			const staticFilter = { _id: { $in: objectIds } }

			const referenceData = dataSource.getObjectsBySelectionType({
				selectionType: selectionType,
				staticFilter: staticFilter,
				contextData,
			})
			referenceDataDict =
				referenceData &&
				referenceData.reduce((dict, item) => {
					dict[item._id] = item
					return dict
				}, {})
		}

		return objectIds.map((_id) => {
			const referenceObject = referenceDataDict[_id] || {}
			return {
				_id,
				...referenceObject,
			}
		})
	}

	getAppVariablesDataSource() {
		return this.appVariableDataSource
	}

	getUrlPathDataSource() {
		return this.dataSourcesDict['__BUILTIN_URL_PATH__DS__']
	}

	getUrlParamsDataSource() {
		return this.dataSourcesDict['__BUILTIN_URL_PARAMS__DS__']
	}

	getDisplayValueFromDataBinding({ contextData, dataBinding }) {
		if (!dataBinding) return undefined

		const dataSourceId = dataBinding.dataSourceId
		if (!dataSourceId) {
			Sentry.captureMessage('Invalid dataBinding: No dataSourceId')
			return undefined
		}

		const dataSource = this.dataSourcesDict[dataSourceId]
		if (!dataSource) {
			throw new Error(
				`Invalid dataBinding: Pointing to non-existent dataSource (dataSourceId: ${dataSourceId})`
			)
		}

		let displayValue
		if (dataBinding.displayPropertyDataBinding) {
			const options = { getDisplayPropertyData: true }
			const displayDataObject = dataSource.getDataFromDataBinding({
				contextData,
				dataBinding,
				options,
			})
			if (displayDataObject) {
				displayValue = displayDataObject[dataBinding.displayPropertyDataBinding.nodeName]
				if (!displayValue) {
					displayValue = displayDataObject._id
				}
			}
		}

		if (!displayValue) {
			const dataObject = dataSource.getDataFromDataBinding({ contextData, dataBinding })
			displayValue = dataObject && dataObject[dataBinding.nodeName]
		}

		return displayValue
	}

	getDataFromDataBinding({ contextData, dataBinding, options }) {
		if (!dataBinding) return undefined

		if (dataBinding.constantValue)
			return {
				[dataBinding.nodeName]: dataBinding.constantValue,
			}

		const dataSourceId = dataBinding.dataSourceId
		if (!dataSourceId) {
			Sentry.captureMessage('Invalid dataBinding: No dataSourceId')
			return undefined
		}

		const dataSource = this.dataSourcesDict[dataSourceId]
		if (!dataSource) {
			throw new Error(
				`Invalid dataBinding: Pointing to non-existent dataSource (dataSourceId: ${dataSourceId})`
			)
		}

		return dataSource.getDataFromDataBinding({ contextData, dataBinding, options })
	}

	getPrimitiveValueFromDataBinding({ dataObject, dataBinding }) {
		const edgeDataBinding = dataBinding.edgeDataBinding || dataBinding
		let dataValue = dataObject && dataObject[edgeDataBinding.nodeName]

		if (edgeDataBinding.valueComponentId && dataValue) {
			dataValue = this._getValueComponent(dataValue, edgeDataBinding.valueComponentId)
		}

		return dataValue
	}

	getDataFromEventHandlerParam(dataValue, eventHandlerValues) {
		const paramId = dataValue.paramId
		if (!paramId) return undefined

		return eventHandlerValues[paramId]
	}

	getDataFromCodeParam(dataValue, codeValues) {
		return codeValues[dataValue.paramId]
	}

	getDataFromDataValue(dataValue, contextData = {}, options = {}, otherContexualData = {}) {
		if (dataValue?.valueProsessors?.length) {
			let value = this._getDataFromDataValue({
				dataValue,
				contextData,
				options: {
					...options,
					getDisplayValue: false,
				},
				otherContexualData,
			})

			// Dont run processors if null or undefined. Unless invert operation or add operations is first operation
			if (
				isNil(value) &&
				![
					e_ValueProcessorOperation.INVERT_VALUE,
					e_ValueProcessorOperation.ADD_SELECTION,
					e_ValueProcessorOperation.REFERENCE_ADD_SELECTION,
				].includes(dataValue.valueProsessors[0].operation)
			)
				return value

			value = this.valueProcessor.process(value, dataValue.valueProsessors, {
				...options,
				timeZone: this.getAppTimeZone(),
				numberFormat: this.getNumberFormat(),
				currency: this.getCurrency(),
				getDataFromDataValue: (dataValue) => this.getDataFromDataValue(dataValue, contextData),
			})

			// Always convert to string when asking for displayValue
			// TODO: Here we also handle getDisplayValue, stringifies numbers and bools.
			// Do we need to handle bool lables? probably not.
			if (options.getDisplayValue) value += ''

			if (options.getDisplayValue && dataValue?.isDateTime) {
				value = this._getDateDisplayValue(value)
			}

			return value
		}

		const value = this._getDataFromDataValue({ dataValue, contextData, options, otherContexualData })

		// Always convert to string when asking for displayValue
		// TODO: We need to take another look at getDisplayValue for DataValues.
		// needed for function-values.
		// Here we donot stringify numbers as these will ruin the implementation of badgeValue (did so i challenges. in UiIconButton) Challenge #4121
		// But we do stringify nubmers for databindings - thus badgevalue should be ruined for those.

		// For Release 100: Assumed ok to remove these.
		// They were introduced to solve #3734 but it had to be solved another way as fixing #3734 introduced #4121
		// if (options.getDisplayValue && !isNil(value) && !isNumber(value)) value += ''

		// if (options.getDisplayValue && dataValue?.isDateTime) {
		// 	value = this._getDateDisplayValue(value)
		// }

		return value
	}

	getObjectsBySelectionType(options) {
		const { dataSourceId } = options
		const dataSource = this.getDataSource(dataSourceId)
		return dataSource.getObjectsBySelectionType(options)
	}

	_getDayjsDateValue(value) {
		const timeZone = this.getAppTimeZone()
		return dayjs(value).tz(timeZone)
	}

	getValueFromValueComponentId(dataValue, valueComponentId) {
		if (!dataValue) return dataValue

		return this._getValueComponent(dataValue, valueComponentId)
	}

	_getValueComponent(dataValue, valueComponentId) {
		const date = this._getDayjsDateValue(dataValue)

		if (!date.isValid()) return undefined

		switch (valueComponentId) {
			case e_DateValueComponents.DAY:
				return date.format('dddd')
			case e_DateValueComponents.DAY_OF_MONTH:
				return date.date()
			case e_DateValueComponents.DAY_OF_WEEK:
				return date.isoWeekday()
			case e_DateValueComponents.HOUR:
				return date.hour()
			case e_DateValueComponents.MINUTE:
				return date.minute()
			case e_DateValueComponents.SECOND:
				return date.second()
			case e_DateValueComponents.MONTH:
				return date.month() + 1
			case e_DateValueComponents.MONTH_NAME:
				return date.format('MMMM')
			case e_DateValueComponents.WEEK_NUMBER:
				return date.isoWeek()
			case e_DateValueComponents.YEAR:
				return date.year()
			default: {
				Sentry.captureException(new Error('getValueComponent: Unknown value component'))
				return undefined
			}
		}
	}

	_getDateDisplayValue(value) {
		if (isNil(value)) return value

		const dateTimeFormat = localeController.getDateTimeFormat()
		const dayjsDate = this._getDayjsDateValue(value)
		if (!dayjsDate.isValid()) return value

		return dateFormatter(dayjsDate, dateTimeFormat)
	}

	getBooleanDisplayValue(dataValue, value) {
		if (!dataValue.dataBinding) return undefined
		const booleanLabelOption = this.getBooleanLabelOptions({
			booleanValue: dataValue.dataBinding.edgeDataBinding || dataValue.dataBinding,
			limitBooleanValues: true,
			selectableBooleanValues: [value],
		})

		return booleanLabelOption[0]?.name
	}

	_getDataFromDataValue({ dataValue, contextData = {}, options = {}, otherContexualData = {} }) {
		if (!isPlainObject(dataValue)) return dataValue
		if (!isString(dataValue.type)) {
			const error = new Error('getDataFromDataValue: dataValue.type is required')
			const state = this.getState()
			const { latestChecksums, latestGlobalChecksums, loadedChecksums, wantedChecksums, currentDeployment } =
				state.metaData

			error.metaData = {
				dataValue,
				checksums: {
					latestChecksums,
					latestGlobalChecksums,
					loadedChecksums,
					wantedChecksums,
				},
				deployment: currentDeployment,
			}
			Sentry.captureException(error)

			throw error
		}

		switch (dataValue.type) {
			case e_DataValueType.DATA_BINDING:
				return (() => {
					if (!dataValue.dataBinding) return undefined

					const edgeDataBinding = dataValue.dataBinding.edgeDataBinding || dataValue.dataBinding
					const nodeName = edgeDataBinding.nodeName || '_id'

					if (
						// multi-cardinality enum or multi-cardinality reference
						dataValue.dataBinding.selectionMode &&
						dataValue.dataBinding.selectionMode !== e_FilterTargetSelectionMode.CONTEXT
					) {
						const dataObjects = this.getDataFromDataBinding({
							contextData,
							dataBinding: dataValue.dataBinding,
						})
						if (!isArray(dataObjects)) return undefined

						// value should be id if reference and value if enum
						const pushToArray = (value, array) => {
							if (array.indexOf(value) === -1) array.push(value) //add to array if not a duplicate
						}
						return dataObjects.reduce((acc, item) => {
							if (!item[nodeName]) return acc

							// multi -> list of values
							if (isArray(item[nodeName])) {
								item[nodeName].map((value) => pushToArray(value, acc))
								return acc
							}

							pushToArray(item[nodeName], acc)
							return acc
						}, [])
					}

					const dataObject = this.getDataFromDataBinding({
						contextData,
						dataBinding: dataValue.dataBinding,
					})

					// multi-cardinality reference
					if (isArray(dataObject)) {
						const returnValues = dataObject.map((object) =>
							getReturnValueFromDataObject({
								dataObject: object,
								dataValue,
								options,
								edgeDataBinding,
								nodeName,
								appController: this,
								contextData,
							})
						)

						if (options.getDisplayValue) return returnValues.join(', ')

						return returnValues
					}

					return getReturnValueFromDataObject({
						dataObject,
						dataValue,
						options,
						edgeDataBinding,
						nodeName,
						appController: this,
						contextData,
					})
				})()

			case e_DataValueType.CONDITION:
				return evaluateCondition({
					contextData,
					conditionNode: dataValue.conditionValue,
					appController: this,
				})

			case e_DataValueType.FUNCTION_VALUE: {
				if (this.pendingFunctions) return // not ready
				const isTranslated = localeController.getIsTranslated()
				return evaluateFunctionValue({
					appController: this,
					contextData: contextData,
					functionValue: dataValue.functionValue,
					selfObject: options.selfObject,
					isTranslated,
					ignoreReturnDatatypeCheck: options.ignoreReturnDatatypeCheck,
				})
			}

			case e_DataValueType.ACTION_PARAM:
				return getActionParamFromContextData(contextData, dataValue.paramId)

			case e_DataValueType.ITERATOR_PARAM:
				return getIteratorParamFromContextData(contextData, dataValue.paramId)

			case e_DataValueType.EVENT_HANDLER_PARAM:
				return this.getDataFromEventHandlerParam(dataValue, otherContexualData.eventHandlerValues)

			case e_DataValueType.CODE_PARAM:
				return this.getDataFromCodeParam(dataValue, otherContexualData.codeValues)

			case e_DataValueType.CONSTANT_FUNCTION:
				return this.__getConstantFunctionValueFromDataValue(dataValue, {
					timeZone: this.getAppTimeZone(),
				})

			case e_DataValueType.SECRET_REFERENCE:
				throw new Error('Cannot use SECRET on client side operations')

			default:
				throw new Error('getDataFromDataValue: Unknown valueType: ' + dataValue.type)
		}
	}

	getAreSomeObjectsSelected(dataSourceId) {
		const dataSource = this.getDataSource(dataSourceId)
		return dataSource && dataSource.getAreSomeObjectsSelected()
	}

	getAreAllObjectsSelected(dataSourceId) {
		const dataSource = this.getDataSource(dataSourceId)
		return dataSource && dataSource.getAreAllObjectsSelected()
	}

	getAllDataForDebug() {
		return this.dataSourcesList.reduce((result, dataSource) => {
			result[dataSource.id] = {
				name: dataSource.name,
				dataReady: dataSource.dataReady,
				data: dataSource.getAllObjects(),
			}

			return result
		}, {})
	}

	getAppTimeZone() {
		return this.appVariableDataSource && this.appVariableDataSource.getAppTimeZone()
	}

	getNumberFormat() {
		return localeController.getNumberFormat()
	}

	getCurrency() {
		return localeController.getCurrency()
	}

	getDateTimeFormat() {
		return localeController.getDateTimeFormat()
	}

	/**************************************************************************
	 *
	 * Metadata Getters
	 *
	 *************************************************************************/

	getEnumeratedType(enumeratedTypeId) {
		const enumeratedType = this.enumeratedTypeDict[enumeratedTypeId]
		if (!enumeratedType) return undefined
		return enumeratedType
	}

	getEnumeratedTypeValue({ enumeratedTypeId, enumeratedTypeValue }) {
		const enumeratedType = this.enumeratedTypeDict[enumeratedTypeId]
		if (!enumeratedType) return undefined
		return enumeratedType.valueDict[enumeratedTypeValue]
	}

	getEnumeratedTypeOptions({ enumeratedTypeId, limitEnumeratedTypeValues, selectableEnumTypeValues = [] }) {
		const enumeratedType = this.getEnumeratedType(enumeratedTypeId)
		if (!enumeratedType) return []

		let options = enumeratedType.values

		if (limitEnumeratedTypeValues)
			options = options.filter((item) => selectableEnumTypeValues.includes(item.id))

		return options
	}

	getEnumeratedTypeNameDict(enumeratedTypeId) {
		const enumeratedType = this.getEnumeratedType(enumeratedTypeId)
		if (!enumeratedType) return {}
		return enumeratedType.values.reduce((dict, item) => {
			dict[item.value] = item.name
			return dict
		}, {})
	}

	getAllEnumeratedTypes() {
		return this.enumeratedTypeDict
	}

	getThemedColorFromEnumeratedType({ enumeratedTypeId, enumeratedTypeValue, darkTheme }) {
		const enumeratedTypeItem = this.getEnumeratedTypeValue({ enumeratedTypeId, enumeratedTypeValue })
		if (!enumeratedTypeItem) return

		return darkTheme ? enumeratedTypeItem.darkThemeColor : enumeratedTypeItem.color
	}

	getBooleanLabelOptions({ booleanValue, limitBooleanValues, selectableBooleanValues, stringifyValues }) {
		const propertyMetadata = this.getPropertyMetadata(booleanValue)

		if (!propertyMetadata) return []

		let options = [
			{
				id: 'labelTrue',
				value: stringifyValues ? 'true' : true,
				name: propertyMetadata.labelTrue ? propertyMetadata.labelTrue : t`True`,
			},
			{
				id: 'labelFalse',
				value: stringifyValues ? 'false' : false,
				name: propertyMetadata.labelFalse ? propertyMetadata.labelFalse : t`False`,
			},
			{
				id: 'labelUndefined',
				value: stringifyValues ? '__unset' : undefined,
				name: propertyMetadata.labelUndefined ? propertyMetadata.labelUndefined : t`Undefined`,
			},
		]

		if (limitBooleanValues)
			options = options.filter((item) => {
				if (isNil(item.value)) return selectableBooleanValues.some(isNil)
				return selectableBooleanValues.includes(item.value)
			})
		return options
	}

	getPropertyMetadata(dataBinding) {
		if (!dataBinding) return null
		if (!dataBinding.dataSourceId) return null
		if (!dataBinding.propertyId) return null

		const dataSource = this.dataSourcesDict[dataBinding.dataSourceId]
		if (!dataSource) return null

		return dataSource.getPropertyMetaData(dataBinding.propertyId)
	}

	getActiveAppId() {
		return this.activeAppId
	}

	// TODO: Find a way to remove this method.
	// Only in use in UiRouteContent
	getNormalViews() {
		const state = this.getState()
		return getNormalViews(state)
	}

	// Metadata/data function actually
	getObjectSelectOptions({
		dataSourceId,
		displayValueDataBinding,
		filter,
		filterFunction,
		sortFunction,
		sortDescriptorArray,
		conditionalFilter,
		contextData,
	}) {
		const dataSource = this.getDataSource(dataSourceId)
		if (!dataSource) return []

		let allObjects = dataSource.getAllObjects()

		if (!displayValueDataBinding) return []
		if (!displayValueDataBinding.nodeName) return []

		if (filter) allObjects = filterFunction(allObjects, filter)

		if (conditionalFilter?.length) {
			conditionalFilter
				.filter((item) => this.getDataFromDataValue(item.condition, contextData))
				.forEach((item) => {
					if (item.staticFilter) {
						allObjects = filterFunction(allObjects, item.staticFilter)
					} else if (item.filterDescriptor) {
						const filter = generateFilterFromGroupNode({
							filterDescriptorNode: item.filterDescriptor,
							contextData: contextData,
							appController: this,
						})
						allObjects = filterFunction(allObjects, filter)
					}
				})
		}

		if (sortDescriptorArray?.length) {
			const sortDescriptorWithDeepSortField = sortDescriptorArray.filter(
				(sortDescriptorItem) => !!sortDescriptorItem.sortField.edgeDataBinding
			)

			let additionalSortDataDict
			if (sortDescriptorWithDeepSortField.length) {
				additionalSortDataDict = allObjects.reduce((dataDict, item) => {
					dataDict[item._id] = sortDescriptorWithDeepSortField.reduce((data, sortDescriptorItem) => {
						const dataObject = this.getDataFromDataBinding({
							contextData: { ...contextData, [dataSource.id]: [item] },
							dataBinding: sortDescriptorItem.sortField,
						})
						data[sortDescriptorItem.sortNodeName] = this.getPrimitiveValueFromDataBinding({
							dataBinding: sortDescriptorItem.sortField,
							dataObject,
						})
						return data
					}, {})
					return dataDict
				}, {})
			}

			const { result, didSort } = sortFunction(allObjects, sortDescriptorArray, {
				additionalSortDataDict,
				appController: this,
			})
			if (didSort) allObjects = result
		}

		const displayNodeName = displayValueDataBinding.nodeName

		if (displayValueDataBinding.enumeratedTypeId) {
			const valueDict = this.getEnumeratedTypeNameDict(displayValueDataBinding.enumeratedTypeId)

			return allObjects.map((object) => ({
				id: object._id,
				value: object._id,
				name: valueDict[object[displayNodeName]] || t`[No value]`,
			}))
		}

		if (displayValueDataBinding.edgeDataBinding) {
			return allObjects.map((object) => {
				const contextData = {
					[dataSourceId]: [object],
				}

				const edgeDataItem = this.getDataFromDataBinding({
					contextData,
					dataBinding: displayValueDataBinding,
				})
				const edgeNodeName = displayValueDataBinding.edgeDataBinding.nodeName
				const name =
					edgeDataItem && !isNil(edgeDataItem[edgeNodeName]) ? edgeDataItem[edgeNodeName] : t`[No value]`

				return {
					id: object._id,
					value: object._id,
					name,
				}
			})
		}

		return allObjects.map((object) => ({
			id: object._id,
			value: object._id,
			name: !isNil(object[displayNodeName]) ? object[displayNodeName] : t`[No value]`,
		}))
	}

	/**
	 * Support default name + displayValueDataValue
	 * Trying it out on Gantt
	 */
	getObjectSelectOptions_v2({
		dataSourceId,
		displayValueDataValue,
		filter,
		filterFunction,
		contextData = {},
	}) {
		const dataSource = this.getDataSource(dataSourceId)
		if (!dataSource) return []

		/**
		 * Filter the objects
		 */
		let allObjects = dataSource.getAllObjects()
		if (filter) allObjects = filterFunction(allObjects, filter)

		if (displayValueDataValue) {
			return allObjects.map((object) => {
				const localContext = {
					...contextData,
					[dataSourceId]: [object],
				}

				const name = this.getDataFromDataValue(displayValueDataValue, localContext) || t`[No value]`

				return {
					id: object._id,
					value: object._id,
					name,
					AF_OBJECT: object,
				}
			})
		} else {
			// No displayValue override - use default
			const displayNameNodeName = dataSource.displayNameNodeName
			return allObjects.map((item) => {
				return {
					id: item._id,
					value: item._id,
					name: displayNameNodeName ? item[displayNameNodeName] : `[${item._id}]`,
					AF_OBJECT: item,
				}
			})
		}
	}

	/**
	 * For use by components that need name/value objects by reference or enumerated types
	 */
	getOptionsForReferenceOrEnumeratedType({
		// Refs
		dataSourceId,
		displayValueDataValue,
		filter,
		filterFunction,
		contextData = {},

		// Enums
		enumeratedTypeId,
		limitEnumeratedTypeValues,
		selectableEnumTypeValues,
	}) {
		if (enumeratedTypeId)
			return this.getEnumeratedTypeOptions({
				enumeratedTypeId,
				limitEnumeratedTypeValues,
				selectableEnumTypeValues,
			})

		return this.getObjectSelectOptions_v2({
			dataSourceId,
			displayValueDataValue,
			filter,
			filterFunction,
			contextData,
		})
	}

	getCurrentUserId() {
		const currentUserDataSource = this.dataSourcesDict['__MECH_CURRENT_USER_DS']
		if (!currentUserDataSource) return

		const data = currentUserDataSource.getAllObjects()
		if (!data?.length) return

		const userId = data[0]._id
		return userId
	}

	/**************************************************************************
	 *
	 * API used mostly by DataSources
	 *
	 *************************************************************************/

	/**
	 * API For DataSources
	 */
	writeSideEffects(sourceDataSource, sideEffects, options = {}) {
		if (!options.logger) options.logger = this.logger
		const logger = options.logger
		const invalidatedDataSourceIdDict = options.invalidatedDataSourceIdDict || {}
		const addOrMergeObjects = options.addOrMergeObjects

		if (!Object.keys(sideEffects).length) {
			// Validate unchanged datasources
			Object.keys(invalidatedDataSourceIdDict).forEach((dataSourceId) => {
				const dataSource = this.dataSourcesDict[dataSourceId]
				if (dataSource) dataSource._forceValid()
			})
			return logger.debug('No side effects to write')
		}

		/**
		 * Replace data in dataSources
		 */
		Object.keys(sideEffects).forEach((dataSourceId) => {
			const dataSource = this.dataSourcesDict[dataSourceId]
			if (dataSource) {
				const { data, count } = sideEffects[dataSourceId] || {}
				dataSource.setTotalObjectCount(count)

				if (addOrMergeObjects || dataSource.id === sourceDataSource.id) {
					// Assumption: self sideEffects should always be merged - return data from server
					dataSource._addOrMergeObjects(data, {
						skipLocalCalculations: true,
					})
				} else {
					dataSource._replaceAllObjects(data, {
						mergeWithExistingObjects: true,
						skipLocalCalculations: true,
					})
				}
			}
		})

		// Validate unchanged datasources
		Object.keys(invalidatedDataSourceIdDict).forEach((dataSourceId) => {
			const dataSource = this.dataSourcesDict[dataSourceId]
			if (dataSource && !sideEffects[dataSourceId]) dataSource._forceValid()
		})

		/**
		 * Recalculate all functions after all datasources
		 * has new data
		 */
		Object.keys(sideEffects).forEach((dataSourceId) => {
			const dataSource = this.dataSourcesDict[dataSourceId]
			if (dataSource) {
				dataSource._runFormulaRecalculation()
				dataSource._runSorting()
				dataSource._runBuiltInPropertyCalculation()
				dataSource._notifyLocalDependencies()
				dataSource._notifyClientFilterDependencies(e_DataSourceChangeType.DATA_REPLACED)
			}
		})

		if (!options.noUpdate) this._writeDiryDataSourcesToRedux()
	}

	invalidateDataSourcesById(dataSourceIdList, { updateGui = false, invalidatedDataSourceIdDict = {} }) {
		if (!dataSourceIdList.length) return

		dataSourceIdList.forEach((dataSourceId) => {
			this.dataSourcesDict[dataSourceId].invalidate(invalidatedDataSourceIdDict)
		})

		updateGui && this._writeDiryDataSourcesToRedux()

		return invalidatedDataSourceIdDict
	}

	/**
	 * Used when inserting data from JSONValueMappers and DataSourceMapper
	 */
	async p_replaceOrAddDataInMultipleDataSources(allData, logger = this.logger) {
		await Promise.all(
			Object.keys(allData).map(async (dataSourceId) => {
				const dataSource = this.dataSourcesDict[dataSourceId]
				let data = allData[dataSourceId].data

				if (!dataSource.local) {
					Sentry.captureException(new Error('Tried to force data into persistent datasource. Failed.'))
					return
				}

				if (data.length > 1 && dataSource.cardinality === e_Cardinality.ONE) data = [data[0]]

				if (dataSource.reverseDependencies.length)
					logger.warning('Replaced data in dataSource, but did not recalculate dependencies')

				switch (allData[dataSourceId].operation) {
					case e_InsertObjectsOperation.UPDATE:
						dataSource.modifySingleCardinalityObject(data[0])
						break
					case e_InsertObjectsOperation.REPLACE:
						dataSource._replaceAllObjects(data, { mergeWithExistingObjects: false })
						break
					default: {
						dataSource._addOrMergeObjects(data)
					}
				}
				if (allData[dataSourceId].setSelected) {
					await dataSource.p_filteredSelection({
						staticFilter: { _id: { $in: data.map((object) => object._id) } },
					})
				}

				if (dataSource.reverseDependencies?.length) {
					const invalidatedDataSourceIdDict = this.invalidateDataSourcesById(
						dataSource.reverseDependencies.map((item) => item.dataSourceId),
						{ updateGui: false }
					)
					const selectionData = dataSource.getDataForSynchronization()
					const dataSourceData = dataSource.getAllObjects()
					try {
						const sideEffects = await getSideEffects(
							dataSource.id,
							{ objectsReplaced: dataSourceData },
							selectionData
						)

						this.writeSideEffects(dataSource, sideEffects, {
							logger,
							invalidatedDataSourceIdDict,
							noUpdate: true,
						})
					} catch (err) {
						logger.error('Failed to get sideffects', { err })
					}
				}
			})
		)

		this._writeDiryDataSourcesToRedux()
	}

	replaceOrAddDataInMultipleDataSources(allData, logger = this.logger) {
		this.p_replaceOrAddDataInMultipleDataSources(allData, logger).catch((err) =>
			this.logger.error('Unable to replace or add data in multiple datasources')
		)
	}

	cascadeDeleteSideEffects(sourceDataSource, cascadeDeletions, logger = this.logger) {
		if (cascadeDeletions.length && cascadeDeletions.some((item) => item.objectIdArray?.length)) {
			logger.debug(`Deletion in ${sourceDataSource.name} resulted in objects removed by cascade delete`)
			cascadeDeletions.forEach((item) => {
				logger.debug('DataSourceId: ' + item.dataSourceId, { payload: item.objectIdArray })
			})
		}

		cascadeDeletions.forEach((item) => {
			const dataSource = this.getDataSource(item.dataSourceId)
			dataSource._removeMultipleObjects(item.objectIdArray)
		})

		this._writeDiryDataSourcesToRedux()
	}

	notifyAttributeChange(dataSourceId, nodeName, value) {
		this.dataSourcesList.forEach((item) => {
			if (item.id === dataSourceId) return // skip self
			if (item.id.includes('.') && item.id.split('.')[0] === dataSourceId) {
				switch (nodeName) {
					case e_BuiltInDataSourceAttributeIds.SKIP_FUNCTION_PROPERTIES:
						item.setSkipFunctionProperties(value)
						break
				}
			}
		})
	}

	async p_notifyObjectChange({
		sourceDataSourceId, // TODO: if sourceDataSourceId is not provided objects will not be cleaned! applies to user accounts
		changedObject,
		changedObjecstArray,
		filter,
		changes,
		objectClassId,
		logger = this.logger,
	}) {
		const dataSourcePropertiesDict = getAllBuiltInPropertiesDict()
		const sourceDataSource = sourceDataSourceId && this.dataSourcesDict[sourceDataSourceId]
		const objectClassProperties =
			sourceDataSource &&
			Object.values(sourceDataSource.getPropertyMetaDict()).filter(
				(propertyMeta) => !propertyMeta.runtime && !dataSourcePropertiesDict[propertyMeta.id]
			)

		const cleanedChangedObjectsArray = []
		if (changedObject) {
			const cleanChangeObject = objectClassProperties
				? objectClassProperties.reduce((cleanObject, propertyMeta) => {
					if (!isUndefined(changedObject[propertyMeta.nodeName]))
						cleanObject[propertyMeta.nodeName] = changedObject[propertyMeta.nodeName]
					return cleanObject
				}, {})
				: changedObject
			cleanedChangedObjectsArray.push(cleanChangeObject)
		}
		if (changedObjecstArray) {
			if (objectClassProperties) {
				changedObjecstArray.forEach((changedObject) => {
					const cleanChangeObject = objectClassProperties.reduce((cleanObject, propertyMeta) => {
						if (!isUndefined(changedObject[propertyMeta.nodeName]))
							cleanObject[propertyMeta.nodeName] = changedObject[propertyMeta.nodeName]
						return cleanObject
					}, {})
					cleanedChangedObjectsArray.push(cleanChangeObject)
				})
			} else {
				cleanedChangedObjectsArray.push(...changedObjecstArray)
			}
		}
		let cleanedChanges
		if (changes) {
			cleanedChanges = objectClassProperties
				? objectClassProperties.reduce((cleanedChanges, propertyMeta) => {
					if (!isUndefined(changes[propertyMeta.nodeName]))
						cleanedChanges[propertyMeta.nodeName] = changes[propertyMeta.nodeName]
					return cleanedChanges
				}, {})
				: changes
		}

		await Promise.all(
			this.dataSourcesList
				.filter((dataSource) => {
					if (dataSource.dataConnector) return false
					if (sourceDataSourceId && dataSource.id === sourceDataSourceId) return false
					if (dataSource.local) return false
					if (dataSource.objectClassId === objectClassId) return true
					return false
				})
				.map(async (dataSource) => {
					if (!cleanedChangedObjectsArray.length && filter) {
						const objectsWithChanges = dataSource.getObjectsBySelectionType({
							selectionType: e_SelectionEffectType.FILTERED,
							staticFilter: filter,
						})
						objectsWithChanges.forEach((item) => {
							cleanedChangedObjectsArray.push({
								...item,
								...cleanedChanges,
							})
						})
					}
					if (cleanedChangedObjectsArray.length)
						await dataSource.p_incommingObjectChange(cleanedChangedObjectsArray, logger)
				})
		)

		this._writeDiryDataSourcesToRedux()
	}

	async p_notifyObjectDeletions({ sourceDataSourceId, objectIds, filter, objectClassId }) {
		// Denne skal fjerne objekter fra andre datakilder enn kilde
		await Promise.all(
			this.dataSourcesList
				.filter((dataSource) => {
					if (dataSource.dataConnector) return false
					if (dataSource.id === sourceDataSourceId) return false
					if (dataSource.local) return false
					if (dataSource.objectClassId === objectClassId) return true
					return false
				})
				.map(async (dataSource) => {
					// check if other qualified objects should/may be added to dataSource
					let haveToRefreshDataSource = false
					let objectIdsForRemoval = objectIds
					if (!objectIdsForRemoval && filter) {
						const objectsForRemoval = dataSource.getObjectsBySelectionType({
							selectionType: e_SelectionEffectType.FILTERED,
							staticFilter: filter,
						})
						objectIdsForRemoval = objectsForRemoval.map((item) => item._id)
					}
					if (dataSource.resultLimit) {
						const oldObjects = dataSource.getAllObjects()
						const oldObjectsCount = oldObjects.length
						if (
							oldObjectsCount === dataSource.resultLimit &&
							oldObjects.some((item) => objectIdsForRemoval.includes(item._id))
						) {
							haveToRefreshDataSource = true
						}
					} else if (dataSource.cardinality === e_Cardinality.ONE) {
						const oldObjects = dataSource.getAllObjects()
						if (oldObjects.length && objectIdsForRemoval.includes(oldObjects[0]._id)) {
							haveToRefreshDataSource = true
						}
					}
					if (haveToRefreshDataSource) {
						await dataSource.p_getAndSetRefreshedData()
					} else {
						dataSource._removeMultipleObjects(objectIdsForRemoval)
						await dataSource.p_getAndSetTotalObjectCount()
					}
				})
		)

		this._writeDiryDataSourcesToRedux()
	}

	/**
	 * TODO: Trenger ny metode for å modifisere fler verdier på en gang.
	 * Skal brukes eksempelvis i Gantt, kart osv.
	 */
	modifySingleValue(dataBinding, oldObject, newValue, contextData = {}, next = () => {}) {
		// multi context property
		if (dataBinding.referenceDataBinding && contextData) {
			const dataSource = this.getDataSourceFromDataBindingProperty(dataBinding)
			if (!dataSource) throw new Error('Unable to find dataSource: ' + dataBinding.dataSourceId)

			const referenceContextObject = oldObject || contextData[dataSource.id]?.[0]
			if (!referenceContextObject) return

			const referenceDataBinding = dataBinding.referenceDataBinding

			if (referenceDataBinding.nodeName === e_BuiltInObjectPropertyIds.IS_SELECTED) {
				dataSource.setOneSelectionValueOptimistically(referenceContextObject._id, newValue)
				return next()
			}

			if (!referenceDataBinding.nodeName) return

			dataSource.modifySingleValueOptimistic({
				dataBinding: referenceDataBinding,
				oldObject: referenceContextObject,
				newValue,
				contextData,
				next,
			})
			return
		}

		if (!dataBinding.nodeName) return
		if (!oldObject) return

		if (dataBinding.nodeName === e_BuiltInObjectPropertyIds.IS_SELECTED) {
			const dataSource = this.getDataSource(dataBinding.dataSourceId)
			if (!dataSource) throw new Error('Unable to find dataSource: ' + dataBinding.dataSourceId)
			dataSource.setOneSelectionValueOptimistically(oldObject._id, newValue)
			return next()
		}

		if (!dataBinding.nodeName) return

		const dataSource = this.getDataSource(dataBinding.dataSourceId)
		if (!dataSource) throw new Error('Unable to find dataSource: ' + dataBinding.dataSourceId)

		dataSource.modifySingleValueOptimistic({ dataBinding, oldObject, newValue, contextData, next })
	}

	modifyFileObject({ dataSourceId, oldObject, dataUrl, fileBlob }) {
		if (!oldObject) return
		const dataSource = this.getDataSource(dataSourceId)
		dataSource.modifyFileObject(oldObject._id, dataUrl, fileBlob)
	}

	/**************************************************************************
	 *
	 * Selection API
	 *
	 *************************************************************************/

	p_setContextSelection(contextData = {}, { operationType, keepExistingSelection, logger = this.logger }) {
		// TODO: Hold igjen evalueringer og kjør alt i ett
		const selectionPromises = Object.keys(contextData)
			.filter((id) => !id.includes('.')) // filter out Generated data sources (also need to filter out reference datasources somehow)
			.map(this.getDataSource)
			.filter((dataSource) => dataSource && contextData[dataSource.id] && contextData[dataSource.id].length)
			.map((dataSource) => {
				switch (operationType) {
					case e_SelectionOperationType.SELECT: {
						if (keepExistingSelection) {
							return dataSource.p_addOneObjectToSelection(contextData[dataSource.id][0]._id, {
								noUpdate: true,
								logger,
							})
						}
						return dataSource.p_setExactlyOneObjectSelected(contextData[dataSource.id][0]._id, {
							noUpdate: true,
							logger,
						})
					}
					case e_SelectionOperationType.UNSELECT:
						return dataSource.p_unselectOneObjectFromSelection(contextData[dataSource.id][0]._id, {
							noUpdate: true,
							logger,
						})
					case e_SelectionOperationType.TOGGLE:
						return dataSource.p_toggleOneObjectInSelection(contextData[dataSource.id][0]._id, {
							noUpdate: true,
							logger,
						})
				}
			})

		if (selectionPromises.length) {
			return new Promise((resolve, reject) => {
				Promise.all(selectionPromises)
					.then(() => {
						this._writeDiryDataSourcesToRedux()
						resolve()
					})
					.catch(reject)
			})
		}

		return Promise.resolve()
	}

	setSingleObjectSelected(dataSourceId, objectId) {
		const dataSource = this.getDataSource(dataSourceId)
		if (dataSource) dataSource.setExactlyOneObjectSelectedOptimistically(objectId)
	}

	toggleSingleObject(dataSourceId, objectId) {
		const dataSource = this.getDataSource(dataSourceId)
		if (dataSource) dataSource.toggleSelectedOptimistically(objectId)
	}

	selectAll(dataSourceId) {
		const dataSource = this.getDataSource(dataSourceId)
		if (dataSource)
			dataSource
				.p_selectAll()
				.then(() => {})
				.catch((err) => this.logger.error('Could not set select all', { err }))
	}

	selectNone(dataSourceId) {
		const dataSource = this.getDataSource(dataSourceId)
		if (dataSource)
			dataSource
				.p_selectNone()
				.then(() => {})
				.catch((err) => this.logger.error('Could not set select none', { err }))
	}

	setSelection(dataSourceId, newSelection = []) {
		const dataSource = this.getDataSource(dataSourceId)
		if (dataSourceId) {
			dataSource
				.p_filteredSelection({
					staticFilter: {
						_id: {
							$in: [...new Set([...newSelection])],
						},
					},
				})
				.then(() => {})
				.catch((err) => this.logger.error('Could not set selection', { err }))
		}
	}

	selectSome(dataSourceId, objectsToSelectIds = []) {
		const dataSource = this.getDataSource(dataSourceId)
		if (dataSource) {
			const selectedIds = dataSource.getSelectedObjects().map((object) => object._id)
			dataSource
				.p_filteredSelection({
					staticFilter: {
						_id: {
							$in: [...new Set([...objectsToSelectIds, ...selectedIds])],
						},
					},
				})
				.then(() => {})
				.catch((err) => this.logger.error('Could not set selection', { err }))
		}
	}

	unselectSome(dataSourceId, objectsToUnselectIds = []) {
		const dataSource = this.getDataSource(dataSourceId)
		if (dataSource) {
			const selectedIds = dataSource.getSelectedObjects().map((object) => object._id)
			dataSource
				.p_filteredSelection({
					staticFilter: {
						_id: {
							$in: selectedIds.filter((objectId) => !objectsToUnselectIds.includes(objectId)),
						},
					},
				})
				.then(() => {})
				.catch((err) => this.logger.error('Could not set selection', { err }))
		}
	}

	/**************************************************************************
	 *
	 * Actions API
	 *
	 *************************************************************************/

	setRunningAction(actionInstance, actionRunner) {
		this.runningActionsDict[actionInstance.id] = actionInstance
		this.activeActionRunners[actionInstance.id] = actionRunner
	}

	actionDone(actionId) {
		const actionWasBlocking = this.runningActionsDict[actionId].pauseRender
		const actionSkipRender = this.runningActionsDict[actionId].skipRender
		delete this.runningActionsDict[actionId]
		delete this.activeActionRunners[actionId]

		if (actionWasBlocking) {
			if (actionSkipRender) {
				// Clear changes
				this._discardUpdatesForUiButEvalOther()
			} else {
				this._writeDiryDataSourcesToRedux()
			}
		}
	}

	getIsActionRunning(actionId) {
		return !!this.runningActionsDict[actionId]
	}

	/**
	 * Workaround for not being able to detect when user clicks cancel
	 * on file input dialog
	 * actionId: when called from eventActions, this is the id of the action to run.
	 */
	cancelFileUpload(actionId) {
		this.cancelFileUploadFunction && this.cancelFileUploadFunction(actionId)
	}

	setCancelFileuploadFunction(callback) {
		this.cancelFileUploadFunction = callback
	}

	clearCancelFileuploadFunction() {
		this.cancelFileUploadFunction = null
	}

	setCurrentUserProfileImage({ profileImageExists, profileImage }) {
		if (!profileImage) throw new Error('profileImage is required in AppController.setCurrentUserProfileImage')
		if (!this.activeAppId) return
		const currentUserDataSource = this.dataSourcesDict['__MECH_CURRENT_USER_DS']
		if (!currentUserDataSource) return

		const data = currentUserDataSource.getAllObjects()
		if (!data?.length) return

		const userId = data[0]._id

		currentUserDataSource.setUserProfileImage(userId, profileImage, profileImageExists)

		this.dataSourcesList
			.filter(
				(dataSource) =>
					dataSource.objectClassId === '__MECH_USER_OC' && dataSource.id !== currentUserDataSource.id
			)
			.forEach((dataSource) => dataSource.setUserProfileImage(userId, profileImage, profileImageExists))

		this._writeDiryDataSourcesToRedux()
	}

	/*****************************************************************************
	 *
	 * Topic API
	 *
	 ****************************************************************************/

	publishToTopic(topicId, message) {
		const handlers = this.topics[topicId]
		if (!handlers) return
		handlers.forEach((handler) => handler(message))
	}

	subscribeToTopic(topicId, handler) {
		if (!this.topics[topicId]) this.topics[topicId] = []
		if (this.topics[topicId].find((item) => item === handler)) return
		this.topics[topicId].push(handler)
	}

	unsubscribeFromTopic(topicId, handler) {
		if (!this.topics[topicId]) return
		this.topics[topicId] = this.topics[topicId].filter((item) => item !== handler)
	}

	/**************************************************************************
	 *
	 * Utils
	 *
	 *************************************************************************/

	// Will make sure that a contextData-object has the latest data available
	refreshContextData(contextData = {}) {
		const newContext = {}

		Object.keys(contextData).forEach((contextDataKey) => {
			if (['action_params', 'iterator_params'].includes(contextDataKey)) {
				newContext[contextDataKey] = contextData[contextDataKey]
			} else {
				const dataSourceId = contextDataKey
				const currentContext = contextData[dataSourceId]
				if (currentContext.length) {
					const dataSource = this.getDataSource(dataSourceId)
					const updatedObject = dataSource.getObjectById(currentContext[0]._id)
					if (updatedObject) newContext[dataSourceId] = [updatedObject]
				}
			}
		})

		return newContext
	}

	/**************************************************************************
	 *
	 * Synchronization API
	 *
	 *************************************************************************/

	/**
	 * Will get all data needed to populate filters on server
	 */
	getDataForSynchronization() {
		return this.dataSourcesList
			.filter((dataSource) => dataSource.isNeededForDataSync())
			.map((dataSource) => {
				return {
					dataSourceId: dataSource.id,
					selections: dataSource.getDataForSynchronization(),
				}
			})
	}

	getDataSourceConfigForSynchronization() {
		return this.dataSourcesList
			.map((dataSource) => dataSource.getDataSourceConfigForSync())
			.filter((item) => item)
	}

	setSynchronizedData(data) {
		Object.keys(data).forEach((dataSourceId) => {
			const dataSource = this.dataSourcesDict[dataSourceId]
			if (dataSource) dataSource.setSyncrhonizedData(data[dataSourceId])
			else
				Sentry.captureException(
					new Error(`Unable to set data for DataSource with id ${dataSourceId}. DataSource not found.`)
				)
		})

		this._writeDiryDataSourcesToRedux()
	}

	/**************************************************************************
	 *
	 * Server Handlers
	 *
	 *************************************************************************/

	serverRequestHandler(request) {
		const dataSourceId = request.payload.dataSourceId
		const dataSource = this.dataSourcesDict[dataSourceId]
		if (!dataSource) {
			if (!this.activeAppId) {
				Sentry.captureException(new Error('Server tried to get data - No app is loaded'))
			} else {
				Sentry.captureException(
					new Error(
						`DataSource not found. Unable to get ${request.type} for DataSource with id ${dataSourceId} in ActiveAppId ${this.activeAppId}. `
					)
				)
			}
			return undefined
		}

		switch (request.type) {
			case operations.GET_FULL_DATASOURCE:
				return dataSource.svrApi_getFullDataSource()
			case operations.GET_OBJECT_BY_ID:
				return dataSource.svrApi_getObjectById(request.payload.objectId)
			case operations.GET_SELECTED_OBJECT_IDS:
				return dataSource.svrApi_getSelectedObjectIds()
			case operations.GET_FILTERED_OBJECT_IDS: // TODO: Dette funker ikke
				return dataSource.svrApi_getFilteredObjectIds()
			case operations.GET_SINGLE_OBJECT:
				return dataSource.svrApi_getSingleObject(request.payload.projection, request.payload.contextObjectId)
			case operations.GET_SINGLE_VALUE:
				return dataSource.svrApi_getSingleValue(request.payload)
			case operations.GET_OBJECT_BY_SELECTION_TYPE:
				return dataSource.getObjectsBySelectionType(request.payload)
			case operations.GET_RUNTIME_OBJECT_VALUES_DICT:
				return dataSource.svrApi_getRuntimeObjectValuesDict(request.selectionType)

			default:
				Sentry.captureException(new Error(`Unknown request from server (request type: ${request.type})`))
		}
	}

	/**
	 * Denne brukes når datakilder på server sender meldinger
	 * til klienten.
	 */
	serverActionHandler(action) {
		const state = this.getState()
		const appReady = isAppReady(state)

		if (!appReady) {
			// app not ready for changemessages, just return
			return
		}

		const dataSourceId = action.payload.dataSourceId
		const dataSource = this.dataSourcesDict[dataSourceId]

		if (!dataSource) {
			Sentry.captureException(new Error(`Unable to find dataSource with id ${dataSourceId}`))
			return
		}

		switch (action.type) {
			case REPLACE_DATA_IN_DATASOURCE:
				liveUpdateLogger.notice('Replace Data', { payload: action.payload.data })
				return dataSource.svrApi_replaceAllData(action.payload.data)
			case INSERT_OBJECT:
				liveUpdateLogger.notice('Insert Object', { payload: action.payload.objectForInsertion })
				return dataSource.svrApi_insertObject(action.payload.objectForInsertion)
			case INSERT_MULTIPLE_OBJECTS:
				liveUpdateLogger.notice('Insert Objects', { payload: action.payload.objectListForInsertion })
				return dataSource.svrApi_insertMultipleObjects(action.payload.objectListForInsertion)
			case MODIFY_OBJECT:
				liveUpdateLogger.notice('Update Object', { payload: action.payload.data })
				return dataSource.svrApi_modifySingleObject(action.payload.objectId, action.payload.data)
			case DELETE_MULTIPLE_OBJECTS:
				liveUpdateLogger.notice('Delete Objects', { payload: action.payload.objectIdArray })
				return dataSource.svrApi_deleteMultipleObjects(action.payload.objectIdArray)

			default:
				Sentry.captureException(new Error(`Unknown action sent to dataSource (action type: ${action.type}`))
		}
	}

	/**************************************************************************
	 *
	 * Model Load and Metadata init
	 *
	 *************************************************************************/
	setDataModelPendingFlag(value) {
		this.pendingDataModel = value
	}

	setFunctionsPendingFlag(value) {
		this.pendingFunctions = value
	}

	setStylesheetPendingFlag(value) {
		this.pendingStylesheet = value
	}

	// This method must be the first method called when changing an app
	setActiveApp(activeAppId) {
		if (!activeAppId) return // Dont unload in case the user opens the same app again
		if (this.activeAppId === activeAppId) return
		this.logger.notice('Initiate app: ' + activeAppId)

		this.activeAppId = activeAppId
		this.enableRenderFlag = false

		// TODO: Make sure all actions are cleaned up

		// Run cleanup
		this.originalMetadata = {}
		this.dataSourcesList.forEach((item) => item.destroy())
		this.dataSourcesList = []
		this.dataSourcesDict = {}

		this.pendingDataModel = true
		this.pendingFunctions = true
		this.pendingStylesheet = true

		this.timers.forEach((timer) => timer.destroy())

		// TODO: Instanciate all known and builtin datasources.
		// No need to get this as a description from the server
	}

	/**
	 * Setting the app-object
	 */
	setOrUpdateApp(appPayload) {
		const { app } = appPayload
		this.logger.notice('Setting metadata: app')

		// Apply timers
		this.timers.forEach((timer) => timer.destroy())
		if (app.timers) this.timers = app.timers.map((item) => new AppTimer(item, this))

		// Check if this initial or an update - initiate if this is an update
		if (this.originalMetadata?.app) this.timers.forEach((timer) => timer.init())

		this.originalMetadata = {
			...this.originalMetadata,
			app: app,
		}
	}

	setOrUpdateDataModel({
		dataSources,
		objectClasses,
		enumeratedTypes,
		dependenciesResolvable,
		appStorageEngine,
	}) {
		this.logger.notice('Setting metadata: dataModel')

		// TODO: Kan denne flyttes til DataModelLoade?
		metadataController.setMetadata({ objectClasses: objectClasses })

		this.dependenciesResolvable = dependenciesResolvable
		this.__appStorageEngine = appStorageEngine

		if (!this.originalMetadata.objectClasses) {
			/******************************************************************************
			 *
			 * First time load of datamodel
			 *
			 *****************************************************************************/

			/**
			 * Adding static datasources
			 */
			this.dataSourcesDict = [
				mechanicalUserDefinition.getDataSource(),
				builtInCurrentUserGroupsDefinition.getDataSource(),
			].reduce((dataSourceDict, dataSourceDescription) => {
				dataSourceDict[dataSourceDescription.id] = new StaticDataSource(dataSourceDescription, this, logger)
				return dataSourceDict
			}, this.dataSourcesDict)

			/**
			 * Adding Dynamic datasources
			 */
			Object.keys(dataSources).forEach((dataSourceId) => {
				if (!this.dataSourcesDict[dataSourceId]) {
					const DataSourceConstructor = getDataSourceConstructor(dataSources[dataSourceId])
					this.dataSourcesDict[dataSourceId] = new DataSourceConstructor(
						dataSources[dataSourceId],
						this,
						this.logger
					)

					if (dataSourceId === '__BUILTIN_RUNTIME_STATE__DS__')
						this.appVariableDataSource = this.dataSourcesDict[dataSourceId]
				}
			})

			/**
			 * Initialize all built in datasources
			 */

			// URL-Path Datasource
			const urlPathDataSource = this.dataSourcesDict['__BUILTIN_URL_PATH__DS__']
			urlPathDataSource.setViewMetadata(enumeratedTypes['__BUILTIN_ENUM__VIEW'])
			urlPathDataSource.setInitialData()

			// Initialize AppVariableDataSource
			this.appVariableDataSource.setInitialData({}, { skipDefaults: true })
			this.appVariableDataSource.setAnoymousStatus(this.getState().authState.isAnonymous)

			// Initialize UrlParams
			// TODO: Move to setActiveApp - No need for metadata to actually be sent
			this.dataSourcesDict['__BUILTIN_URL_PARAMS__DS__'] &&
				this.dataSourcesDict['__BUILTIN_URL_PARAMS__DS__'].setInitialData()

			// Map all to array
			this.dataSourcesList = Object.values(this.dataSourcesDict)

			// // and initiate calendars
			this.dataSourcesList.forEach((dataSource) => {
				if (dataSource.isCalendar) dataSource.setInitialData()

				dataSource.initializeStorage(this.__appStorageEngine)
			})
		} else {
			/******************************************************************************
			 *
			 * Update/Merge of datamodel
			 *
			 *****************************************************************************/

			/**
			 * Update user defined datasources
			 */
			if (dataSources !== this.originalMetadata.dataSources) {
				const builtInDataSourceIds = [
					'__MECH_CURRENT_USER_DS',
					'__BUILT_IN_CURRENT_USER_GROUPS__DS__',
					'__BUILTIN_URL_PATH__DS__',
					'__BUILTIN_URL_PARAMS__DS__',
					'__BUILTIN_RUNTIME_STATE__DS__',
				]

				const removedDataSourceIds = this.dataSourcesList
					.filter(
						(dataSource) => !dataSources[dataSource.id] && !builtInDataSourceIds.includes(dataSource.id)
					)
					.map((item) => item.id)

				if (removedDataSourceIds.length) {
					this.dataSourcesList = this.dataSourcesList.filter(
						(dataSource) => !removedDataSourceIds.includes(dataSource.id)
					)
					removedDataSourceIds.forEach((dataSourceId) => {
						const removedDsName = this.dataSourcesDict[dataSourceId]?.name || ''
						this.logger.debug('Remove dataSource: ' + removedDsName)
						this.dataSourcesDict[dataSourceId].destroy()
						delete this.dataSourcesDict[dataSourceId]
					})
				}

				Object.keys(dataSources).forEach((dataSourceId) => {
					const newDataSourceMeta = dataSources[dataSourceId]
					if (this.dataSourcesDict[dataSourceId]) {
						const objectClassMetadata = newDataSourceMeta.objectClassId
							? metadataController.getObjectClassMetadata(newDataSourceMeta.objectClassId)
							: undefined

						this.dataSourcesDict[dataSourceId].setDataSourceModel(newDataSourceMeta, objectClassMetadata)
					} else {
						// TODO: Are new data actually populated with data?
						const DataSourceConstructor = getDataSourceConstructor(newDataSourceMeta)
						const newClientDataSource = new DataSourceConstructor(newDataSourceMeta, this, this.logger)
						this.logger.debug('Added new dataSource: ' + newClientDataSource.name)
						this.dataSourcesList.push(newClientDataSource)
						this.dataSourcesDict[newClientDataSource.id] = newClientDataSource
					}
				})
			} else if (objectClasses !== this.originalMetadata.objectClasses) {
				metadataController.setMetadata({ objectClasses: objectClasses })
				Object.values(this.dataSourcesDict).forEach((dataSource) => {
					const objectClassMetadata = dataSource.objectClassId
						? metadataController.getObjectClassMetadata(dataSource.objectClassId)
						: undefined

					this.dataSourcesDict[dataSource.id].setDataSourceModel(undefined, objectClassMetadata)
				})
			}

			if (enumeratedTypes !== this.originalMetadata.enumeratedTypes) {
				// Apply translations
				this.dataSourcesList.forEach((item) => {
					if (item.enumeratedTypeId) {
						const enumValueDict = enumeratedTypes[item.enumeratedTypeId]?.valueDict
						enumValueDict && item.translateData(enumValueDict)
					}
				})

				// Apply views if changed
				this.dataSourcesDict['__BUILTIN_URL_PATH__DS__'].setViewMetadata(
					enumeratedTypes['__BUILTIN_ENUM__VIEW']
				)
			}
		}

		this.originalMetadata = {
			...this.originalMetadata,
			enumeratedTypes: enumeratedTypes,
			dataSources: dataSources,
			objectClasses: objectClasses,
		}

		this.enumeratedTypeDict = enumeratedTypes

		this.pendingDataModel = false
		this._writeDiryDataSourcesToRedux()
	}

	initializeRuntimeDataSources() {
		this.dataSourcesList
			.filter(
				(dataSource) =>
					!['__BUILTIN_URL_PARAMS__DS__', '__BUILTIN_URL_PATH__DS__'].includes(dataSource.id) &&
					dataSource.local
			)
			.forEach((dataSource) => dataSource.setInitialData({}))
	}

	initializeEnumDataSources() {
		this.dataSourcesList
			.filter((dataSource) => dataSource.enumeratedTypeId)
			.forEach((dataSource) => dataSource.setInitialData({}))
	}

	setInitialDataFromCache(initialData) {
		this.logger.notice('Setting initial data from cache')
		this.dataSourcesList
			.filter(
				(dataSource) => !['__BUILTIN_URL_PARAMS__DS__', '__BUILTIN_URL_PATH__DS__'].includes(dataSource.id)
			)
			.forEach((dataSource) => {
				const dataSourceData = initialData[dataSource.id]
				if (dataSourceData && !dataSource.initOnLoad)
					dataSource.setInitialData(dataSourceData, { skipDefaults: true })
				else dataSource.setInitialData()
			})

		this.dataSourcesList.forEach((dataSource) => dataSource.init())

		this.enableRenderFlag = true
		this._writeDiryDataSourcesToRedux()
	}

	setInitialDataFromServer(initialData) {
		this.logger.notice('Setting initial data from server')
		this.dataSourcesList
			.filter(
				(dataSource) => !['__BUILTIN_URL_PARAMS__DS__', '__BUILTIN_URL_PATH__DS__'].includes(dataSource.id)
			)
			.forEach((dataSource) => {
				const dataSourceData = initialData[dataSource.id]
				if (dataSourceData) dataSource.setInitialData(dataSourceData)
			})

		this.dataSourcesList.forEach((dataSource) => dataSource.init())

		this.enableRenderFlag = true
		this._writeDiryDataSourcesToRedux()
	}

	getAllDataForCache() {
		return this.dataSourcesList.reduce((result, dataSource) => {
			result[dataSource.id] = {
				data: dataSource.getUnfilteredData(),
			}
			return result
		}, {})
	}

	onAppLoadExecuted() {
		this.timers.forEach((timer) => timer.init())
	}

	/**************************************************************************
	 *
	 * Language API
	 *
	 *************************************************************************/

	// TODO: Move this to the provider for AppVariableDataSource
	resetLanguage() {
		if (!this.appVariableDataSource) return // Too early
		this.activeLanguageId = null
		this.appVariableDataSource.resetLanguage()
		this._writeDiryDataSourcesToRedux()
	}

	setLanguage(languageId) {
		if (!this.appVariableDataSource) return // Too early
		this.activeLanguageId = languageId
		this.appVariableDataSource.setLanguage(languageId)
		this._writeDiryDataSourcesToRedux()
	}

	getActiveLanguageId() {
		return this.activeLanguageId
	}

	// TODO: create as a selector or just hardcode it to the one place it is used
	getActiveAppApiRoot() {
		if (!this.activeAppId) return null
		return `/api/v1/apps/${this.activeAppId}`
	}

	getGlobalSettings() {
		const state = this.getState()
		return state.metaData.globalSettings
	}

	getActiveAppMetadata() {
		const state = this.getState()
		return state.metaData.app
	}

	/**************************************************************************
	 *
	 * Theme API
	 *
	 *************************************************************************/

	reevaluateActiveTheme() {
		this.appVariableDataSource.reevaluateActiveTheme()
	}

	/**************************************************************************
	 *
	 * Devtools Injection of metadata
	 *
	 *************************************************************************/

	setExtendMetadata(extendedMetadata) {
		this.dataSourcesList.forEach((item) => {
			if (item.setExtendMetadata) item.setExtendMetadata(extendedMetadata)
		})
	}

	/**************************************************************************
	 *
	 * Debugger API
	 *
	 *************************************************************************/
	subscribeDevTools(handler) {
		logger.debug('Devtools Attached - overall performance may be slower')
		this.devtoolSubscription = handler
	}

	unsubscribeDevTools() {
		logger.debug('Devtools Detached')
		this.devtoolSubscription = null
	}

	debugSetStoreUpdateLog(enabled) {
		this.__debugUiUpdateLogEnabled = !!enabled
	}

	debugSetPrintMetadataStats(enabled) {
		this.__debugPrintMetadataStats = !!enabled
	}

	debugGetDataSourcesInUse() {
		return this.dataSourcesList
	}

	debugGetMetadata() {
		if (!this.getState) return null
		const state = this.getState()
		return state.metaData
	}
}
